import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useEditWorkflowContext } from '../../EditWorkflowContext/EditWorkflowContext';

import styles from './Header.module.scss';
import { useCanSave } from './useCanSave';

import { ChevronClosed, CircleQuestion, Trash } from 'assets';
import { IconButton } from 'components/IconButton/IconButton';
import { JitButton } from 'components/JitButton/JitButton';
import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitSwitchToggle, JitSwitchToggleProps } from 'components/JitSwitchToggle/JitSwitchToggle';
import { JitText } from 'components/JitText/JitText';
import { JitTextInput } from 'components/JitTextInput/JitTextInput';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { constants } from 'globalConstants';
import { i18n } from 'locale/i18n';
import { useSaveWorkflow } from 'pages/WorkflowsPage/EditWorkflowPage/components/Header/useSaveWorkflow';
import colors from 'themes/colors.module.scss';

interface IWorkflowHeaderTexts {
  backButton: string;
  tooltips: {
    details: string;
    deleteWorkflow: string;
    activateWorkflow: string;
  },
  save: string;
}

export const Header = () => {
  const {
    workflow,
    setWorkflow,
  } = useEditWorkflowContext();

  const {
    isNewWorkflow,
    canSave,
  } = useCanSave(workflow);

  const {
    mutateAsync: saveWorkflow,
    isLoading,
  } = useSaveWorkflow({
    isNewWorkflow,
  });

  const { WORKFLOWS } = constants.routes;
  const { t } = i18n;

  const workflowHeaderTexts: IWorkflowHeaderTexts = useMemo(() => t('pages.workflows.editWorkflowPage.header', { returnObjects: true }), [t]);

  const navigate = useNavigate();

  const onToggleChange: JitSwitchToggleProps['onChange'] = useCallback((event, checked) => {
    setWorkflow((prevWorkflow) => ({
      ...prevWorkflow,
      isEnabled: checked,
    }));
  }, [setWorkflow]);
  const onClickBack = useCallback(() => {
    navigate(`/${WORKFLOWS}`);
  }, [navigate, WORKFLOWS]);

  const deleteWorkflow = useCallback(() => {
    // TODO: Implement delete workflow
  }, []);

  const onClickInfo = useCallback(() => {
    // TODO: Implement delete workflow
  }, []);

  const onWorkflowNameChange = useCallback((newName: string) => {
    setWorkflow((prevWorkflow) => ({
      ...prevWorkflow,
      name: newName,
    }));
  }, [setWorkflow]);

  if (!workflow) return null;

  return (
    <div className={styles.wrapper}>
      <div>
        <JitButton disableRipple onClick={onClickBack}>
          <JitIcon color={colors.iris} icon={ChevronClosed} rotateDegrees={90} size={22} />

          <JitText color={colors.iris} noWrap text={workflowHeaderTexts.backButton} />
        </JitButton>
      </div>

      <JitTextInput
        defaultValue={workflow.name}
        fullWidth
        onChange={onWorkflowNameChange}
        withBorder={false}
      />

      <div className={styles.actionButtons}>
        <div>
          <IconButton color={colors.iris} icon={CircleQuestion} onClick={onClickInfo} size={16} tooltipText={workflowHeaderTexts.tooltips.details} />
        </div>

        <div>
          <IconButton icon={Trash} onClick={deleteWorkflow} size={22} tooltipText={workflowHeaderTexts.tooltips.deleteWorkflow} />
        </div>

        <JitTooltip followCursor={false} placement='top' title={workflowHeaderTexts.tooltips.activateWorkflow}>
          <JitSwitchToggle checked={workflow.isEnabled} onChange={onToggleChange} />
        </JitTooltip>

        <JitGradientButton data-testid='saveWorkflowButton' disabled={!canSave || isLoading} isLoading={isLoading} onClick={() => { saveWorkflow(); }} width={isLoading ? 50 : 'fit-content'}>
          <div className={styles.saveButtonTextWrapper}>
            <JitText size='s' text={workflowHeaderTexts.save} />
          </div>
        </JitGradientButton>
      </div>
    </div>
  );
};
