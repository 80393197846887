import { FC } from 'react';
import { NodeProps, Handle, Position } from 'reactflow';

import { JitIcon } from 'components/JitIcon/JitIcon';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import styles from 'pages/WorkflowsPage/EditWorkflowPage/components/WorkflowGraph/CustomNode.module.scss';
import { WorkflowNodeData } from 'pages/WorkflowsPage/EditWorkflowPage/components/WorkflowGraph/types';
import { NODE_WIDTH, NODE_HEIGHT } from 'pages/WorkflowsPage/EditWorkflowPage/components/WorkflowGraph/visualUtils';
import { useEditWorkflowContext } from 'pages/WorkflowsPage/EditWorkflowPage/EditWorkflowContext/EditWorkflowContext';
import colors from 'themes/colors.module.scss';
import { StepType } from 'types/interfaces/Workflows/IWorkflow';
import { capitalizeFirstLetter } from 'utils';

export const CustomNode: FC<NodeProps<WorkflowNodeData>> = ({ id, data: { icon, step } }) => {
  const { openStepDetails } = useEditWorkflowContext();
  const isTrigger = step.stepType === StepType.Trigger;
  const isPlaceholderName = ['Description', 'Select a trigger'].includes(step.name); // TODO: Change to 'isConfigured'
  let backgroundColor = colors.cards;
  if (isTrigger) backgroundColor = colors.triggerNodeBackground;
  return (
    <div
      className={styles.customNode}
      data-testid={`customGraphNode-${id}`}
      onClick={() => openStepDetails(id)}
      role='button'
      style={{
        width: NODE_WIDTH,
        height: NODE_HEIGHT,
        borderColor: colors.nodeBorder,
        background: backgroundColor,
      }}
      tabIndex={0}
    >
      <div className={styles.iconContainer}>
        <JitIcon icon={icon} size={30} />
      </div>

      <div className={styles.textContainer} style={{ maxWidth: NODE_WIDTH }}>
        <TooltipOnlyOnOverflow color={isPlaceholderName ? colors.iris : colors.white} noWrap overflowHiddenEllipsis size='m' text={step.name} />

        <TooltipOnlyOnOverflow className={styles.subText} muted noWrap overflowHiddenEllipsis size='s' text={capitalizeFirstLetter(step?.stepType || 'Choose Step')} />
      </div>

      <Handle position={Position.Top} style={{ visibility: 'hidden' }} type='target' />

      <Handle position={Position.Bottom} style={{ visibility: 'hidden' }} type='source' />
    </div>
  );
};
