
import { FC, useMemo, useCallback } from 'react';

import styles from './ActionSection.module.scss';

import { JitButton } from 'components/JitButton/JitButton';
import { JitMenu, OnMenuItemClick } from 'components/JitMenu/JitMenu';
import { JitText } from 'components/JitText/JitText';
import { ActionConfigInput } from 'pages/WorkflowsPage/EditWorkflowPage/components/StepDetails/components/ActionSection/ActionConfigInput';
import { useEditWorkflowContext } from 'pages/WorkflowsPage/EditWorkflowPage/EditWorkflowContext/EditWorkflowContext';
import colors from 'themes/colors.module.scss';
import { IActionStep } from 'types/interfaces/Workflows/IWorkflow';

interface Props {
  selectedStep: IActionStep;
}
export const ActionSection: FC<Props> = ({ selectedStep }) => {
  const {
    workflow,
    setWorkflow,
    stepsOptions,
    setSelectedStepId,
    selectedStepId,
  } = useEditWorkflowContext();

  const actionOptions = useMemo(() => (stepsOptions?.actionOptions || []), [stepsOptions]);

  const onSelectActionOptions: OnMenuItemClick = useCallback(({ itemKey }) => {
    const triggerOption = actionOptions.find((option) => option.type === itemKey);
    if (!triggerOption) return; // Shouldn't happen

    setWorkflow((prevWorkflow) => ({
      ...prevWorkflow,
      steps: prevWorkflow.steps.map((step) => (step.id === selectedStep.id ? {
        ...step,
        type: triggerOption.type,
        name: triggerOption.label,
      } : step)),
    }));
    setSelectedStepId(selectedStepId);
  }, [actionOptions, selectedStep.id, selectedStepId, setSelectedStepId, setWorkflow]);

  const actionOption = useMemo(() => actionOptions.find((option) => option.type === selectedStep.type), [actionOptions, selectedStep.type]);

  const configSectionsToShow = useMemo(() => {
    if (!actionOption) return [];
    return actionOption.configSchema.filter((section) => {
      if (!section.dependsOn) return true;
      return Object.entries(section.dependsOn).every(([key, value]) => selectedStep.config[key] === value);
    });
  }, [actionOption, selectedStep.config]);

  const updateActionConfig = useCallback((key: string, value: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
    setWorkflow((prevWorkflow) => ({
      ...prevWorkflow,
      steps: prevWorkflow.steps.map((step) => (step.id === selectedStep.id ? {
        ...step,
        config: {
          ...step.config,
          [key]: value,
        },
      } : step)),
    }));
  }, [selectedStep.id, setWorkflow]);

  const menuItems = useMemo(() => actionOptions.map((option) => ({
    itemKey: option.type,
    displayText: option.label,
    itemContent: <JitText text={option.label} />,
    isSelected: workflow?.trigger?.type === option.type,
  })), [actionOptions, workflow?.trigger?.type]);

  const triggerTypeDisplayName = actionOption?.label || 'pages.workflows.editWorkflowPage.stepDetails.action.addAction';
  return (
    <>
      <div className={styles.actionSection} data-testid='selectActionType'>
        <JitText bold muted size='s' text='pages.workflows.editWorkflowPage.stepDetails.action.ctaQuestion' />

        <JitMenu
          menuItems={menuItems}
          menuSelectedDesign='fillRow'
          onMenuItemClick={onSelectActionOptions}
          paperProps={{ style: { width: 180 } }}
          shouldCloseOnItemClick
        >
          <JitButton noHover variant='outlined' width={180}>
            <JitText color={colors.iris} text={triggerTypeDisplayName} />
          </JitButton>
        </JitMenu>
      </div>

      {configSectionsToShow.map((configSchema) => (
        <div key={configSchema.key} className={styles.actionSection}>
          <JitText bold muted size='s' text={configSchema.description} />

          <ActionConfigInput actionConfig={selectedStep.config} configSchema={configSchema} updateActionConfig={updateActionConfig} />
        </div>
      ))}
    </>
  );
};
