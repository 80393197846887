import { useCallback, useMemo } from 'react';

import { mockWorkflow } from 'mocks/Workflows/mockWorkflow';
import { getApiUrls } from 'services/apiUrls';
import { useClient } from 'services/useClient';
import { IPaginatedResponse } from 'types/interfaces/IPaginatedResponse/IPaginatedResponse';
import { IWorkflow } from 'types/interfaces/Workflows/IWorkflow';
import { IWorkflowStepOptions } from 'types/interfaces/Workflows/IWorkflowStepOptions';
import { camelizeSnakeCaseKeys, snakeCaseCamelizeKeys } from 'utils/functions/camelCaseConverter';

const WORKFLOWS_PAGE_LIMIT = 25;

export const useWorkflowsService = () => {
  const { client } = useClient();

  const statuses = useMemo(() => ['error', 'running', 'completed', undefined], []);

  const getMockedData = useCallback(async ({
    after,
    limit = WORKFLOWS_PAGE_LIMIT,
  }: { after?: string; limit?: number }): Promise<IPaginatedResponse<IWorkflow>> => {
    const totalWorkflows = Array.from({ length: 100 }, (_, index) => {
      const lastRunStatus = statuses[Math.floor(Math.random() * statuses.length)];
      return {
        ...mockWorkflow,
        id: `workflow-${index}`,
        name: `Workflow ${index + 1}`,
        isEnabled: Math.random() > 0.5,
        lastRunStatus,
        lastRunAt: lastRunStatus ? new Date().toISOString() : null,
      } as IWorkflow;
    });
    const startIndex = after ? parseInt(after, 10) + 1 : 0;
    const endIndex = startIndex + limit;
    const paginatedData = totalWorkflows.slice(startIndex, endIndex);

    // delay to simulate API response time
    await new Promise<void>((resolve) => {
      setTimeout(resolve, 1000);
    });

    return {
      data: paginatedData,
      metadata: {
        count: totalWorkflows.length,
        limit,
        after: endIndex < totalWorkflows.length ? (endIndex - 1).toString() : undefined,
      },
    };
  }, [statuses]);

  const getWorkflow = useCallback(async (workflowId: string): Promise<IWorkflow | undefined> => {
    const url = getApiUrls.workflowsService.getWorkflow(workflowId);

    const response = await client.get<IWorkflow>({
      url,
      allowedStatuses: [200],
    });

    if (response?.status === 200) {
      return camelizeSnakeCaseKeys(response.data) as IWorkflow;
    }
    throw new Error('Failed to fetch workflow');
  }, [client]);

  const getWorkflows = useCallback(async ({ after }: { after: string }): Promise<IPaginatedResponse<IWorkflow> | undefined> => {
    const isApiReady = false;
    if (!isApiReady) {
      return await getMockedData({  // eslint-disable-line
        after,
        limit: WORKFLOWS_PAGE_LIMIT,
      });
    }
    const url = getApiUrls.workflowsService.getWorkflows();

    const response = await client.get<IPaginatedResponse<IWorkflow[]>>({
      url,
      allowedStatuses: [200],
      requestConfig: {
        params: {
          after,
          limit: WORKFLOWS_PAGE_LIMIT,
        },
      },
    });

    if (response?.status === 200) {
      return camelizeSnakeCaseKeys(response.data) as IPaginatedResponse<IWorkflow>;
    }

    throw new Error('Failed to fetch workflows');
  }, [client, getMockedData]);

  const createWorkflow = useCallback(async (workflow: IWorkflow): Promise<IWorkflow> => {
    const url = getApiUrls.workflowsService.createWorkflow();

    const response = await client.post<IWorkflow>({
      url,
      allowedStatuses: [201],
      requestConfig: {
        data: snakeCaseCamelizeKeys(workflow),
      },
    });
    if (response?.status === 201) {
      return camelizeSnakeCaseKeys(response.data) as IWorkflow;
    }
    throw new Error('Failed to create workflow');
  }, [client]);

  const updateWorkflow = useCallback(async (workflow: IWorkflow): Promise<IWorkflow> => {
    const url = getApiUrls.workflowsService.updateWorkflow(workflow.id);

    const response = await client.put<IWorkflow>({
      url,
      allowedStatuses: [200],
      requestConfig: {
        data: snakeCaseCamelizeKeys(workflow),
      },
    });
    if (response?.status === 200) {
      return camelizeSnakeCaseKeys(response.data) as IWorkflow;
    }
    throw new Error('Failed to update workflow');
  }, [client]);

  const getStepsOptions = useCallback(async (): Promise<IWorkflowStepOptions> => {
    const url = getApiUrls.workflowsService.getStepsOptions();

    const response = await client.get<IWorkflowStepOptions>({
      url,
      allowedStatuses: [200],
    });

    if (response?.status === 200) {
      return camelizeSnakeCaseKeys(response.data) as IWorkflowStepOptions;
    }

    throw new Error('Failed to fetch trigger options');
  }, [client]);

  return {
    getWorkflows,
    getStepsOptions,
    createWorkflow,
    updateWorkflow,
    getWorkflow,
  };
};
