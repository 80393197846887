import { useState } from 'react';

import { Header } from './components/Header/Header';
import { StepDetails } from './components/StepDetails/StepDetails';
import { ViewToggle, WorkflowView } from './components/ViewToggle/ViewToggle';
import { WorkflowGraph } from './components/WorkflowGraph/WorkflowGraph';
import styles from './EditWorkflowPage.module.scss';

import { JitSlidingPanel } from 'components/JitSlidingPanel/JitSlidingPanel';
import { useEditWorkflowContext } from 'pages/WorkflowsPage/EditWorkflowPage/EditWorkflowContext/EditWorkflowContext';

export const EditWorkflowPage = () => {
  const [view, setView] = useState<WorkflowView>('diagram');

  const {
    selectedStepId,
    closeStepDetailsPanel,
  } = useEditWorkflowContext();

  return (
    <>
      <div className={styles.pageControls}>
        <Header />

        <ViewToggle setView={setView} view={view} />
      </div>

      {view === 'diagram' ? <WorkflowGraph /> : <div data-testid='history-view' />}

      <JitSlidingPanel
        closePanel={closeStepDetailsPanel}
        isOpen={!!selectedStepId}
        width='50%'
      >
        {selectedStepId && <StepDetails />}
      </JitSlidingPanel>
    </>
  );
};
