import { FC } from 'react';

import { ActionConfigInputProps } from '../../ActionConfigInput';

import styles from './VendorPicker.module.scss';

import { getIconByVendor } from 'components/AssetType/utils/getIconByVendor';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';

export const VendorPicker: FC<ActionConfigInputProps> = ({
  configSchema,
  actionConfig,
  updateActionConfig,
}) => {
  const vendors = configSchema.enum || [];
  const isSelected = (vendor: string) => actionConfig[configSchema.key] === vendor;
  const onCardClick = (vendor: string) => {
    updateActionConfig(configSchema.key, vendor);
  };
  return (
    <div className={styles.wrapper}>
      {vendors.map((vendor) => (
        <div
          key={vendor}
          className={isSelected(vendor) ? styles.cardSelected : styles.card}
          data-testid={`VendorPickerCard-${vendor}`}
          onClick={() => { onCardClick(vendor); }}
          role='button'
          tabIndex={0}
        >

          <JitIcon icon={getIconByVendor(vendor, undefined, true)} size={33} />

          <JitText bold text={`vendor.${vendor}`} />
        </div>
      ))}
    </div>
  );
};
