import { styled } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup, { toggleButtonGroupClasses } from '@mui/material/ToggleButtonGroup';

import colors from 'themes/colors.module.scss';

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    borderColor: '#90CAF929',
    padding: '4px 8px 4px 10px',
    boxShadow: '0px 0px 100px 0px #0288D11A',
  },
}));

export const StyledToggleButton = styled(ToggleButton)(() => ({
  backgroundColor: colors.appBgColor,
  padding: '4px 8px 4px 10px',
  '&:hover': {
    backgroundColor: colors.navbarSelected,
  },
  '&.Mui-selected': {
    backgroundColor: colors.cards,
    '&:hover': {
      backgroundColor: colors.navbarSelected,
    },
  },
}));
