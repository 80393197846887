import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';

import { AssetManagementTable } from '../AssetManagementTable/AssetManagementTable';

import styles from './SelectionList.module.scss';

import { IAsset } from 'types/interfaces';

interface Props {
  assets: IAsset[];
  checkAsset: (assetId: string, checked: boolean) => void;

}

export const SelectionList: FC<Props> = ({
  assets,
  checkAsset,
}) => {
  const { uiEnableManualAssetScan } = useFlags();

  const headersList = [{
    label: 'dialogs.assetsManagement.selectionList.tableHeaderAssetName',
    key: 'asset_name',
  }];

  if (uiEnableManualAssetScan) {
    headersList.push({
      label: 'dialogs.assetsManagement.selectionList.tableHeaderActions',
      key: 'actions',
    });
  }

  return (
    <div>
      {assets.length > 0 && (
      <div className={styles.contentContainer} data-testid='owner'>
        <AssetManagementTable assets={assets} checkAsset={checkAsset} headers={headersList} />
      </div>
      )}
    </div>
  );
};
