import { useNavigate } from 'react-router-dom';

import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';
import { JitText } from 'components/JitText/JitText';
import { constants } from 'globalConstants';
import { NEW_WORKFLOW_PATH_PARAM } from 'pages/WorkflowsPage/EditWorkflowPage/constants';

export const CreateWorkflowButton = () => {
  const navigate = useNavigate();

  const { WORKFLOWS } = constants.routes;

  const handleClick = async () => {
    navigate(`/${WORKFLOWS}/${NEW_WORKFLOW_PATH_PARAM}`);
  };

  return (
    <JitGradientButton
      data-testid='workflows-button-create'
      height={45}
      onClick={handleClick}
      width={200}
    >

      <JitText horizontalAlign='center' text='pages.workflows.workflowsListPage.buttons.create' />
    </JitGradientButton>
  );
};
