import { FC } from 'react';

import styles from './SeeDetailsButton.module.scss';

import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';
import { JitText } from 'components/JitText/JitText';

interface Props {
  onClick: () => void;
  disabled?: boolean;
}

export const SeeDetailsButton: FC<Props> = ({ onClick, disabled }) => (
  <div className={styles.wrapper}>
    <JitGradientButton data-testid='saveTagButton' disabled={disabled} onClick={onClick} width='fit-content'>
      <div className={styles.textWrapper}>
        <JitText size='s' text='pages.risks.resources.resourcesDashboard.seeDetails' />
      </div>
    </JitGradientButton>
  </div>
);
