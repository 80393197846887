import { FC } from 'react';

import { ActionConfigInputProps } from '../../ActionConfigInput';

import styles from './SlackChannelPicker.module.scss';

import { JitAutoCompleteForm } from 'components/JitAutoCompleteForm/components/JitAutocompleteForm';
import { useGetChannelsMenuItems } from 'pages/TeamsPortal/GuidePage/components/useGetMenuItems/useGetChannelsMenuItems';

export const SlackChannelPicker: FC<ActionConfigInputProps> = ({
  configSchema,
  actionConfig,
  updateActionConfig,
}) => {
  const {
    menuItems,
    isLoading,
  } = useGetChannelsMenuItems();
  return (
    <div className={styles.wrapper} data-testid='slackChannelPicker'>
      <JitAutoCompleteForm
        disabled={isLoading}
        getOptionLabel={(option) => option}
        isLoading={isLoading}
        isSingleValue
        options={menuItems.map((item) => item.itemKey)}
        selectedValue={actionConfig[configSchema.key] as string}
        setSelectedValue={(channel) => updateActionConfig(configSchema.key, channel || '')}
      />
    </div>
  );
};
