import { useMutation, useQueryClient } from 'react-query';

import { useEditWorkflowContext } from 'pages/WorkflowsPage/EditWorkflowPage/EditWorkflowContext/EditWorkflowContext';
import { useWorkflowsService } from 'services/WorkflowsService/useWorkflowsService';
import { Queries } from 'types/enums/Queries';

interface Props {
  isNewWorkflow: boolean;
}

export const useSaveWorkflow = ({
  isNewWorkflow,
}: Props) => {
  const queryClient = useQueryClient();

  const {
    workflow,
    setWorkflow,
  } = useEditWorkflowContext();
  const { createWorkflow, updateWorkflow } = useWorkflowsService();

  return useMutation(
    async () => {
      if (!workflow) throw new Error('Workflow is undefined'); // Ensures workflow exists

      if (isNewWorkflow) {
        return await createWorkflow(workflow); // eslint-disable-line @typescript-eslint/return-await
      }
      return await updateWorkflow(workflow); // eslint-disable-line @typescript-eslint/return-await
    },
    {
      onSuccess: (response) => {
        setWorkflow(response);

        // Update the fetchedWorkflow in the cache
        queryClient.setQueryData([Queries.Workflow], response);
      },
      onError: (error) => {
        console.error('Error saving workflow:', error);
        // Handle errors, maybe set an error state or display a toast notification
      },
    },
  );
};
