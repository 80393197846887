import { FC } from 'react';

import { JitText } from 'components/JitText/JitText';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import { ITableCell } from 'types/interfaces';
import { formatDateTime } from 'utils';

export const DateTimeCell: FC<ITableCell> = ({ cell: { value: { dateTime, showTime } } }) => {
  if (!dateTime) {
    return null;
  }
  const dateTimeStrings = formatDateTime(dateTime);

  const text = showTime ? dateTimeStrings.fullDateTime : dateTimeStrings.dateOnly;
  return (
    <TooltipOnlyOnOverflow tooltipText={dateTimeStrings.fullDateTime}>
      <JitText noWrap overflowHiddenEllipsis text={text} />
    </TooltipOnlyOnOverflow>
  );
};
