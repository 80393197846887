import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { isEmpty } from 'lodash';
import { FC, useState } from 'react';

import styles from './JitAutoCompleteForm.module.scss';

import { Delete } from 'assets';
import { CircularProgressBar } from 'components/CircularProgressBar/CircularProgressBar';
import { JitText } from 'components/JitText/JitText';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import colors from 'themes/colors.module.scss';
import { stopPropagation } from 'utils';

interface IProps<T> {
  placeHolder?: string
  options?: T
  getOptionLabel?: (v: T) => string
  getOptionKey?: (v: T) => string
  selectedValue: T
  setSelectedValue: (value: T) => void
  endAdornment?: JSX.Element
  freeSolo?: boolean
  isSingleValue?: boolean
  isLoading?: boolean
  loadingText?: string
  disabled?: boolean
  disableCloseOnSelect?: boolean
  onScroll?: (event: React.UIEvent<HTMLUListElement>) => void
  onInputChange?: (value: string) => void
  noOptionsText?: string
  clearOnBlur?: boolean
  autoFocus?: boolean
  type?: string
}

/* This wrapper is here to support the border
of the TextField in the dialog */
const JitTextField = styled(TextField, {
  shouldForwardProp: (props) => props !== 'focusColor',
// eslint-disable-next-line @typescript-eslint/no-unused-vars
})((p) => ({
  // input label root
  '& label, & .MuiInputBase-input': {
    color: colors.gray,
  },
  // input label when focused
  '& label.Mui-focused': {
    color: colors.iris,
    borderWidth: '1px',
  },
  // focused color for input with variant='standard'
  '& .MuiInput-underline:after': {
    borderBottomColor: colors.iris,
    borderWidth: '1px',
  },
  // focused color for input with variant='filled'
  '& .MuiFilledInput-underline:after': {
    borderBottomColor: colors.iris,
    borderWidth: '1px',
  },
  // focused color for input with variant='outlined'
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: colors.iris,
      borderWidth: '1px',
    },
  },
}));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props<T = any> = IProps<T>;

export const JitAutoCompleteForm: FC<Props> = ({
  placeHolder,
  options,
  getOptionLabel,
  getOptionKey,
  selectedValue,
  setSelectedValue,
  endAdornment,
  freeSolo,
  isSingleValue,
  isLoading,
  loadingText,
  disabled,
  disableCloseOnSelect,
  onScroll,
  onInputChange,
  noOptionsText,
  clearOnBlur = true,
  autoFocus = true,
  type = 'text',
}) => {
  const [textInput, setTextInput] = useState('');

  const handleInputChange = (event: React.SyntheticEvent<Element, Event>, value: string) => {
    if (event && event.type === 'change') {
      onInputChange?.(value);
    }

    if (isSingleValue && !options) {
      setSelectedValue(value);
    }
    setTextInput(value);
  };

  const noOptionsClass = options ? styles.noOptions : styles.hideNoOptions;

  return (
    <FormControl
      className={styles.formControl}
      data-testid='formControl'
      fullWidth
      onClick={stopPropagation}
    >
      <Autocomplete
        autoHighlight
        classes={{
          noOptions: noOptionsClass,
          loading: styles.loading,
        }}
        clearOnBlur={clearOnBlur}
        disableClearable
        disableCloseOnSelect={disableCloseOnSelect}
        disabled={disabled}
        filterSelectedOptions
        freeSolo={freeSolo}
        getOptionLabel={getOptionLabel}
        ListboxProps={{
          className: styles.listBox,
          onScroll,
        }}
        loading={isLoading}
        loadingText={loadingText}
        multiple={!isSingleValue}
        noOptionsText={noOptionsText || undefined}
        onChange={(event, value) => setSelectedValue(value)}
        onInputChange={handleInputChange}
        options={options || []}
        renderInput={(params) => (
          <JitTextField
            {...params}
            autoFocus={autoFocus}
            data-testid='share-input'
            InputLabelProps={{
              shrink: false,
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: isLoading ? (
                <CircularProgressBar className={styles.loadingProgress} size='18px' />
              ) : (
                !!options && (endAdornment || params.InputProps.endAdornment)
              ),
            }}
            label={
              (textInput || !isEmpty(selectedValue)) ? ' ' : placeHolder
              /* DO NOT CHANGE THE EMPTY PLACEHOLDER */
            }
            size='small'
            type={type}
          />
        )}
        renderOption={(props, option) => {
          const value = getOptionLabel ? getOptionLabel(option) : option;
          return (
            <Box
              {...props}
              key={getOptionKey ? getOptionKey(option) : value}
              className={styles.optionBox}
              component='li'
              data-testid='li-box-option'
            >
              <TooltipOnlyOnOverflow tooltipText={value}>
                <JitText noWrap overflowHiddenEllipsis text={value} />
              </TooltipOnlyOnOverflow>
            </Box>
          );
        }}
        renderTags={(values, getTagProps) => values.map((value, index) => (
          <Chip
            data-testid='tag-chip'
            {...getTagProps({ index })}
            key={getOptionLabel ? getOptionLabel(value) : value}
            clickable={false}
            deleteIcon={<Delete />}
            label={getOptionLabel ? getOptionLabel(value) : value}
            size='small'
            sx={{
              height: '20px',
              borderColor: colors.gray,
              borderWidth: '1px',
              borderRadius: '4px',
            }}
            variant='outlined'
          />
        ))}
        value={selectedValue || null}
      />
    </FormControl>
  );
};
