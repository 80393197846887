import { difference } from 'lodash';
import { useState, useMemo, useCallback, PropsWithChildren, FC, useEffect, Dispatch, SetStateAction } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { NEW_WORKFLOW_PATH_PARAM } from '../constants';
import { buildInitialWorkflow } from '../utils/buildInitialWorkflow';

import { EditWorkflowContext } from './EditWorkflowContext';

import { useWorkflowsService } from 'services/WorkflowsService/useWorkflowsService';
import { Queries } from 'types/enums/Queries';
import { IWorkflow, Step, DefinedStep } from 'types/interfaces/Workflows/IWorkflow';
import { IWorkflowStepOption } from 'types/interfaces/Workflows/IWorkflowStepOptions';

export const EditWorkflowProvider: FC<PropsWithChildren> = ({ children }) => {
  const [workflow, setWorkflow] = useState<IWorkflow | undefined>();
  const [selectedStepId, setSelectedStepId] = useState<string | undefined>();

  const { workflowId } = useParams<{ workflowId: string }>();
  const {
    getStepsOptions,
    getWorkflow,
  } = useWorkflowsService();
  const {
    data: stepsOptions,
    isLoading: isLoadingStepsOptions,
  } = useQuery(
    [Queries.WorkflowsStepsOptions],
    getStepsOptions,
    {
      keepPreviousData: true,
    },
  );
  const fetchWorkflow = useCallback(async () => {
    if (workflowId === NEW_WORKFLOW_PATH_PARAM) return buildInitialWorkflow();
    return await getWorkflow(workflowId!); // eslint-disable-line @typescript-eslint/return-await
  }, [getWorkflow, workflowId]);

  const {
    data: fetchedWorkflow,
    isLoading: isLoadingWorkflow,
  } = useQuery(
    [Queries.Workflow, workflowId],
    fetchWorkflow,
    { enabled: !!workflowId },
  );

  useEffect(() => {
    if (!isLoadingWorkflow && fetchedWorkflow) {
      setWorkflow(fetchedWorkflow);
    }
  }, [fetchedWorkflow, isLoadingWorkflow]);

  const openStepDetails = useCallback((stepId: string) => {
    setSelectedStepId(stepId);
  }, []);

  const closeStepDetailsPanel = useCallback(() => {
    setSelectedStepId(undefined);
  }, []);

  const getStepNextOptions = useCallback(
    (step: Step): IWorkflowStepOption[] => {
      const isConfigured = !!step.type && !!step.stepType;
      if (!isConfigured || !stepsOptions) return [];

      const definedStep = step as DefinedStep;

      const stepTypeOptions = stepsOptions[`${definedStep.stepType}Options`] as IWorkflowStepOption[];
      const stepOutput = stepTypeOptions.find((option) => option.type === definedStep.type)?.output;
      if (!stepOutput || !stepOutput?.length) return [];
      const nonTriggerStepOptions = [
        ...stepsOptions.conditionOptions,
        ...stepsOptions.actionOptions,
      ] as IWorkflowStepOption[];
      return nonTriggerStepOptions.filter(
        (option) => difference(option.input, stepOutput).length === 0,
      );
    },
    [stepsOptions],
  );

  const setWorkflowSafe: Dispatch<SetStateAction<IWorkflow>> = useCallback((newWorkflow: SetStateAction<IWorkflow>) => {
    if (typeof newWorkflow === 'function') {
      setWorkflow((prevWorkflow) => {
        if (!prevWorkflow) return undefined;
        return newWorkflow(prevWorkflow);
      });
    } else {
      setWorkflow(newWorkflow);
    }
  }, [setWorkflow]);

  const contextValue = useMemo(
    () => ({
      workflow,
      setWorkflow: setWorkflowSafe,
      openStepDetails,
      getStepNextOptions,
      stepsOptions,
      isLoadingStepsOptions,
      selectedStepId,
      setSelectedStepId,
      closeStepDetailsPanel,
      isLoadingWorkflow,
      fetchedWorkflow,
    }),
    [
      setWorkflowSafe,
      workflow,
      isLoadingWorkflow,
      fetchedWorkflow,
      openStepDetails,
      getStepNextOptions,
      stepsOptions,
      isLoadingStepsOptions,
      selectedStepId,
      closeStepDetailsPanel,
    ],
  );

  return (
    <EditWorkflowContext.Provider value={contextValue}>
      {children}
    </EditWorkflowContext.Provider>
  );
};
