import { FC, useMemo, useCallback } from 'react';

import styles from './SelectStepType.module.scss';

import { JitButton } from 'components/JitButton/JitButton';
import { JitMenu } from 'components/JitMenu/JitMenu';
import { JitText } from 'components/JitText/JitText';
import { useEditWorkflowContext } from 'pages/WorkflowsPage/EditWorkflowPage/EditWorkflowContext/EditWorkflowContext';
import colors from 'themes/colors.module.scss';
import { DefinedStep, StepType, IConditionStep, IActionStep } from 'types/interfaces/Workflows/IWorkflow';
import { capitalizeFirstLetter } from 'utils';

interface Props {
  selectedStep: DefinedStep;
}

export const SelectStepType: FC<Props> = ({ selectedStep }) => {
  const {
    setWorkflow,
    selectedStepId,
  } = useEditWorkflowContext();

  const menuItems = useMemo(
    () => [StepType.Condition, StepType.Action].map((option) => ({
      itemKey: option,
      displayText: option,
      itemContent: <JitText text={capitalizeFirstLetter(option)} />,
      isSelected: selectedStep?.stepType === option,
    })),
    [selectedStep],
  );

  const onSelectOption = useCallback(
    (itemKey: StepType) => {
      const updatedStep = {
        ...selectedStep,
        stepType: itemKey,
        name: 'Description',
      } as IConditionStep | IActionStep;

      setWorkflow((prevWorkflow) => ({
        ...prevWorkflow,
        steps: prevWorkflow.steps.map((step) => (step.id === selectedStepId ? updatedStep : step)),
      }));
    },
    [selectedStep, selectedStepId, setWorkflow],
  );

  if (!selectedStep.stepType) return null;

  return (
    <div className={styles.wrapper}>
      <JitText bold muted size='s' text='pages.workflows.editWorkflowPage.stepDetails.selectType.ctaQuestion' />

      <JitMenu
        menuItems={menuItems}
        menuSelectedDesign='fillRow'
        onMenuItemClick={({ itemKey }) => onSelectOption(itemKey as StepType)}
        paperProps={{ style: { width: 180 } }}
        shouldCloseOnItemClick
      >
        <JitButton noHover variant='outlined' width={150}>
          <JitText color={colors.iris} text={capitalizeFirstLetter(selectedStep.stepType)} />
        </JitButton>
      </JitMenu>
    </div>
  );
};
