import { FC, useMemo } from 'react';

import { configInputsByTypeMap } from './configInputsByTypeMap';

import { IActionStep } from 'types/interfaces/Workflows/IWorkflow';
import { IWorkflowActionStepOptionConfigSchema } from 'types/interfaces/Workflows/IWorkflowStepOptions';

export interface ActionConfigInputProps {
  configSchema: IWorkflowActionStepOptionConfigSchema;
  actionConfig: IActionStep['config'];
  updateActionConfig: (key: string, value: any) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export const ActionConfigInput: FC<ActionConfigInputProps> = ({
  configSchema,
  actionConfig,
  updateActionConfig,
}) => {
  const ConfigInput = useMemo(() => configInputsByTypeMap[configSchema.type], [configSchema.type]);
  if (!ConfigInput) {
    console.error(`No config input for type ${configSchema.type} - please extend configInputsByTypeMap.ts with a component for this type.`);
    return null;
  }

  return (
    <ConfigInput actionConfig={actionConfig} configSchema={configSchema} updateActionConfig={updateActionConfig} />
  );
};
