import { FC, useState } from 'react';

import { JitSwitchToggle } from 'components/JitSwitchToggle/JitSwitchToggle';
import { ITableCell } from 'types/interfaces';

export const SwitchCell: FC<ITableCell> = ({ cell: { value } }) => {
  const [checked, setChecked] = useState(value);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    // TODO: Implement update logic
  };

  return (
    <JitSwitchToggle
      checked={checked}
      onChange={handleChange}
    />
  );
};
