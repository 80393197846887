import { Switch } from '@mui/material';
import * as React from 'react';
import { FC } from 'react';

import styles from './JitSwitchToggle.module.scss';

export interface JitSwitchToggleProps {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  dataTestId?: string;
}

export const JitSwitchToggle: FC<JitSwitchToggleProps> = ({
  checked,
  onChange,
  dataTestId,
}) => (
  <Switch
    checked={checked}
    classes={{
      switchBase: styles.switchBase,
      track: checked ? styles.checkedTrack : styles.uncheckedTrack,
      thumb: styles.thumb,
    }}
    data-testid={dataTestId || 'JitSwitchToggle'}
    onChange={onChange}
  />
);
