import { useMemo } from 'react';

import { DateTimeCell } from 'components/JitTable/templates/DateTimeCell/DateTimeCell';
import { StatusCellV2 } from 'components/JitTable/templates/StatusCell/StatusCellV2';
import { SwitchCell } from 'components/JitTable/templates/SwitchCell/SwitchCell';
import { UserCell } from 'components/JitTable/templates/UserCell/UserCell';
import { i18n } from 'locale/i18n';
import { NameCell } from 'pages/TeamsPage/SubPages/TeamsPage/components/TeamsTable/Cells/NameCell/NameCell';
import { IWorkflow } from 'types/interfaces/Workflows/IWorkflow';
import { TableColumn } from 'types/types';

interface IColumnHeaders {
  name: string;
  description: string;
  runs: string;
  lastRun: string;
  createdBy: string;
  createdAt: string;
  lastModified: string;
  status: string;
  enabled: string;
}

export const useGetColumns = () => {
  const { t } = i18n;
  const columnHeaders: IColumnHeaders = useMemo(() => t('pages.workflows.workflowsListPage.table.columns', { returnObjects: true }), [t]);

  const columnsData = useMemo(() => {
    const columns = [
      {
        Header: columnHeaders.name,
        accessor: (workflow: IWorkflow) => ({
          name: workflow.name,
          description: workflow.description,
        }),
        Cell: NameCell,
        width: '30%',
      },
      {
        Header: columnHeaders.createdAt,
        accessor: (workflow: IWorkflow) => ({
          dateTime: workflow.createdAt,
          showTime: false,
        }),
        Cell: DateTimeCell,
        width: '10%',
      },
      {
        Header: columnHeaders.createdBy,
        accessor: 'creatorUserName',
        Cell: UserCell,
        width: '10%',
      },
      {
        Header: columnHeaders.lastRun,
        accessor: (workflow: IWorkflow) => ({
          dateTime: workflow.lastRunAt,
          showTime: true,
        }),
        Cell: DateTimeCell,
        width: '15%',
      },
      {
        Header: columnHeaders.status,
        accessor: 'lastRunStatus',
        Cell: StatusCellV2,
        width: '10%',
      },
      {
        Header: columnHeaders.enabled,
        accessor: 'isEnabled',
        Cell: SwitchCell,
        width: '10%',
      },
    ];
    return columns;
  }, [columnHeaders]);

  return {
    columns: columnsData as TableColumn<object>[],
  };
};
