import { FC, useState, useCallback } from 'react';

import styles from './StepName.module.scss';

import { EditPen, Check } from 'assets';
import { JitButton } from 'components/JitButton/JitButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { JitTextInput } from 'components/JitTextInput/JitTextInput';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { useEditWorkflowContext } from 'pages/WorkflowsPage/EditWorkflowPage/EditWorkflowContext/EditWorkflowContext';
import colors from 'themes/colors.module.scss';
import { DefinedStep, StepType } from 'types/interfaces/Workflows/IWorkflow';

interface Props {
  selectedStep: DefinedStep;
}

export const StepName: FC<Props> = ({ selectedStep }) => {
  const [isInEditMode, setIsInEditMode] = useState(false);
  const {
    setWorkflow,
  } = useEditWorkflowContext();

  const updateStepName = useCallback((newName: string) => {
    if (newName === selectedStep.name) return;
    setWorkflow((prevState) => {
      if (selectedStep.stepType === StepType.Trigger) {
        return {
          ...prevState,
          trigger: {
            ...prevState.trigger,
            name: newName,
          },
        };
      }
      return {
        ...prevState,
        steps: prevState.steps.map((step) => (step.id === selectedStep.id ? {
          ...step,
          name: newName,
        } : step)),
      };
    });
  }, [selectedStep, setWorkflow]);

  const toggleEditMode = useCallback(() => {
    setIsInEditMode((prevState) => !prevState);
  }, []);

  const tooltipText = isInEditMode ? 'pages.workflows.editWorkflowPage.stepDetails.editNameTooltip.save' : 'pages.workflows.editWorkflowPage.stepDetails.editNameTooltip.edit';
  return (
    <div className={styles.wrapper}>
      {isInEditMode ? (<JitTextInput onChange={updateStepName} value={selectedStep.name} />) : (<JitText bold data-testid='stepName' size='l' text={selectedStep.name} />)}

      <JitButton data-testid='editStepNameButton' onClick={toggleEditMode}>
        <JitTooltip followCursor={false} placement='top' title={tooltipText}>
          <JitIcon color={colors.iris} icon={isInEditMode ? Check : EditPen} size={14} />
        </JitTooltip>
      </JitButton>
    </div>
  );
};
