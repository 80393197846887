import { isEqual, isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { NEW_WORKFLOW_PATH_PARAM } from 'pages/WorkflowsPage/EditWorkflowPage/constants';
import { useEditWorkflowContext } from 'pages/WorkflowsPage/EditWorkflowPage/EditWorkflowContext/EditWorkflowContext';
import { IWorkflow } from 'types/interfaces/Workflows/IWorkflow';

export const useCanSave = (workflow: IWorkflow | undefined) => {
  const { workflowId } = useParams<{ workflowId: string }>();
  const { fetchedWorkflow } = useEditWorkflowContext();

  const isNewWorkflow = workflowId === NEW_WORKFLOW_PATH_PARAM;
  const hasWorkflowChanged = useMemo(() => !isEqual(workflow, fetchedWorkflow), [workflow, fetchedWorkflow]);
  // TODO: This is a placeholder for validation
  const isWorkflowValid = useMemo(() => Boolean(workflow?.name) && Boolean(workflow?.trigger) && !isEmpty(workflow?.steps), [workflow]);

  const canSave = useMemo(
    () => (isNewWorkflow || hasWorkflowChanged) && isWorkflowValid,
    [isNewWorkflow, hasWorkflowChanged, isWorkflowValid],
  );

  return {
    isNewWorkflow,
    canSave,
  };
};
