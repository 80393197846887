import { Avatar } from '@mui/material';
import { FC } from 'react';

import styles from './UserCell.module.scss';

import { JitText } from 'components/JitText/JitText';
import { ITableCell } from 'types/interfaces';

export const UserCell: FC<ITableCell> = ({ cell: { value } }) => (
  <div className={styles.wrapper}>
    <Avatar
      alt={value}
      src={`https://ui-avatars.com/api/?name=${value}`}
      sx={{
        width: 20,
        height: 20,
      }}
    />

    <JitText size='s' text={value} />
  </div>
);
