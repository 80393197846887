import { FC, useMemo } from 'react';

import { JitGraph } from 'components/JitGraph/JitGraph';
import { AddStepNode } from 'pages/WorkflowsPage/EditWorkflowPage/components/WorkflowGraph/AddStepNode';
import { CustomEdge } from 'pages/WorkflowsPage/EditWorkflowPage/components/WorkflowGraph/CustomEdge';
import { CustomNode } from 'pages/WorkflowsPage/EditWorkflowPage/components/WorkflowGraph/CustomNode';
import { getLayoutedElements } from 'pages/WorkflowsPage/EditWorkflowPage/components/WorkflowGraph/visualUtils';
import { useEditWorkflowContext } from 'pages/WorkflowsPage/EditWorkflowPage/EditWorkflowContext/EditWorkflowContext';
import { translateWorkflowToGraph } from 'pages/WorkflowsPage/EditWorkflowPage/utils/translateWorkflowToGraph';
import colors from 'themes/colors.module.scss';

const edgeTypes = {
  customEdge: CustomEdge,
};
const nodeTypes = {
  customNode: CustomNode,
  addStepNode: AddStepNode,
};

export const WorkflowGraph: FC = () => {
  const { workflow, isLoadingWorkflow, isLoadingStepsOptions, getStepNextOptions } = useEditWorkflowContext();

  const graph = useMemo(() => {
    if (!workflow) {
      return {
        nodes: [],
        edges: [],
      };
    }
    return translateWorkflowToGraph(workflow, getStepNextOptions);
  }, [getStepNextOptions, workflow]);

  const { nodes, edges } = graph;
  const isLoading = isLoadingWorkflow || isLoadingStepsOptions;
  return (
    <JitGraph backgroundColor={colors.appBgColor} edges={edges} edgeTypes={edgeTypes} getLayoutedElements={getLayoutedElements} isLoading={isLoading} nodes={nodes} nodeTypes={nodeTypes} />
  );
};
