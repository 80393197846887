import { Dispatch, FC, SetStateAction } from 'react';

import { StyledToggleButton, StyledToggleButtonGroup } from './StyledToggleGroup';
import styles from './ViewToggle.module.scss';

import { JitText } from 'components/JitText/JitText';

const viewOptions = ['diagram', 'history'];

export type WorkflowView = typeof viewOptions[number];

interface ViewToggleProps {
  view: WorkflowView;
  setView: Dispatch<SetStateAction<WorkflowView>>;
}

export const ViewToggle: FC<ViewToggleProps> = ({ view, setView }) => (
  <StyledToggleButtonGroup
    className={styles.wrapper}
    data-testid='view-toggle'
    exclusive
    onChange={(_, newView) => newView && setView(newView)}
    value={view}
  >
    {viewOptions.map((option) => (
      <StyledToggleButton key={option} value={option}>
        <JitText className={styles.buttonText} text={`pages.workflows.editWorkflowPage.views.${option}`} />
      </StyledToggleButton>
    ))}
  </StyledToggleButtonGroup>
);
