import { FC, useMemo } from 'react';

import { getStatusDetails } from './utils/statusesUtils';

import { JitTag } from 'components/JitTag/JitTag';
import { ITableCell } from 'types/interfaces';

// StatusCellV2 is a more generic component compared to the existing StatusCell component,
// which is specifically tied to finding statuses. This version uses a utility function
// to handle various statuses dynamically, making it reusable for different contexts.

export const StatusCellV2: FC<ITableCell> = ({ cell: { value } }) => {
  const statusObject = useMemo(() => getStatusDetails(value), [value]);
  if (!statusObject) {
    return null;
  }
  return (
    <JitTag bold color={statusObject.color} text={statusObject.text} />
  );
};

